import moment from 'moment'
import Model, { fields } from '@thinknimble/tn-models'

import CompanyAPI from './api'
import Contact from '@/services/contacts'
import DealInvestor from '../dealInvestors/models'

export default class Company extends Model {
  static api = CompanyAPI.create(Company)

  static id = new fields.CharField({ readOnly: true })
  static name = new fields.CharField()
  static recordTypeId = new fields.CharField()
  static website = new fields.CharField()
  static description = new fields.CharField()
  static publicDescription = new fields.CharField()
  static mostRecentOpportunityStatus = new fields.CharField()
  static mostRecentOpportunityPoint = new fields.CharField()
  static stage = new fields.CharField()
  static sanitizedDescription = new fields.CharField()
  static sanitizedWebsite = new fields.CharField()
  static salesforceUrl = new fields.CharField()
  static dateInvestedIn = new fields.CharField()
  static pbTotalMoneyRaised = new fields.IntegerField()
  static wasOnMidasList = new fields.BooleanField()
  static ceo = new fields.ModelField({ ModelClass: Contact })
  static salesforceDealInvestors = new fields.ArrayField({
    type: new fields.ModelField({ ModelClass: DealInvestor }),
  })

  static getAnnotatedIndustryInvestmentsFieldName(industryId) {
    const standardizedIndustryId =
      industryId.charAt(0).toUpperCase() + industryId.slice(1).toLowerCase()
    return `numInvestmentsInIndustry${standardizedIndustryId}`
  }

  static getAnnotatedAllInvestmentsFieldName() {
    return 'numTotalInvestments'
  }

  static getAnnotatedInvestmentsInIndustriesFieldName() {
    return 'numInvestmentsInIndustries'
  }

  get isPortfolioCompany() {
    return this.recordTypeId === process.env.VUE_APP_PORTFOLIO_COMPANY_RECORD_TYPE_ID
  }

  get isProspectiveCompany() {
    return this.recordTypeId === process.env.VUE_APP_PROSPECTIVE_COMPANY_RECORD_TYPE_ID
  }

  get dateInvestedInMoment() {
    return moment(this.dateInvestedIn)
  }

  get companyNameWithCeoName() {
    if (this.ceo) {
      return `${this.name} (${this.ceo.fullName})`
    }
    return this.name
  }
}
